<template>
  <div coach-my-page-layout>
    <div class="nav-bg"></div>
    <div class="wrap">
      <div class="wrap-nav">
        <slot name="nav"></slot>
      </div>
      <div class="wrap-content">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less">
@import '~@/less/coaching.less';
@media (@ds-up) {
  #app { overflow: unset;
    > .contents-wrapper { overflow: unset;}
  }
}
[coach-my-page-layout] {
  .bgc(white);
  .wrap {.responsive-w(1300);.m(auto);.h(100%);.flex;flex-direction: row;.min-h(inherit)}
  .wrap-content {.responsive-w(1000);}
  .wrap-nav {.w(300); .ib; .bgc(transparent); .pt(40); }
  @media (@mypage-desktop-down) {
    .wrap-nav { .hide }
    .wrap-content { .ml(auto); .mr(auto); }
  }
}
</style>
