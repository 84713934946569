<template>
  <router-link :to="{name: pathName}" my-page-nav-tab-item :class="{active}">
    <p>
      <slot></slot>
    </p>
    <svg-angle />
  </router-link>
</template>

<script>
import SvgAngle from '@shared/graphics/svg-angle.vue';

export default {
  components: { SvgAngle },
  props: {
    pathName: {
      type: String,
      default: '',
    },
  },
  computed: {
    active() {
      return this.$route.name === this.pathName;
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[my-page-nav-tab-item] {.bgc(#fff);.h(60);.w(100%);.br(10);.flex;.c(#000);.flex-ai(center);.p(20);border-width: 1px; border-style: solid; border-color: lightgray;
  & + & {.mt(10)}
  [svg-angle] {.t-r(270deg);}
  [svg-angle] * {stroke: #000;}
  &.active {border-color: #2c81ff;}
  &:hover, &:active {border-color: #000;}
  > p {flex-grow: 1}
}
</style>
