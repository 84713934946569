<template>
  <coach-my-page-layout coach-my-page>
    <template #nav>
      <my-page-nav :my-info="myInfo" />
    </template>
    <template #content>
      <router-view></router-view>
    </template>
  </coach-my-page-layout>
</template>

<script>
import MyPageNav from '@/views/components/coaching/coach-my-page/MyPageNav.vue';
import CoachMyPageLayout from '@/views/layout/CoachMyPageLayout.vue';

export default {
  name: 'CoachMyPage',
  components: { CoachMyPageLayout, MyPageNav },
  computed: {
    matchedMediaDevice() {
      return this.$store.state?.browser?.matchedMediaDevice;
    },
    mobile() {
      return this.matchedMediaDevice === 'M' || this.matchedMedia === 'TP';
    },
    myInfo() {
      return this.$store.state?.auth?.myInfo;
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[coach-my-page] {
  @media (@mypage-desktop-down) {
    [my-page-nav] {.hide}
  }
}
</style>
