<template>
  <div my-page-nav>
    <div class="nav-content">
      <div class="nav-head">
        <p class="intro-text" v-if="isCoach">안녕하세요<br /><b>{{ nickname }}</b> 코치님</p>
        <p class="intro-text" v-else>안녕하세요<br /><b>{{ nickname }}</b> 님</p>
        <client-only>
          <point-icon shape="transparent-alt" :value="upBalanceAmount" clickable @click="onRecharge" v-if="!isCoach" />
        </client-only>
      </div>
      <div>
        <my-page-nav-tab-item path-name="CoachUserClasses">MY 강의실</my-page-nav-tab-item>
        <client-only>
          <my-page-nav-tab-item path-name="CoachUserPoint" v-if="!isCoach">MY 포인트</my-page-nav-tab-item>
        </client-only>
        <client-only>
          <my-page-nav-tab-item path-name="CoachUserQuestions" v-if="!isCoach">MY 질문</my-page-nav-tab-item>
        </client-only>
        <my-page-nav-tab-item path-name="CoachUserLikedCoaches">MY 즐겨찾기</my-page-nav-tab-item>
        <my-page-nav-tab-item path-name="CoachUserModify">회원정보 수정</my-page-nav-tab-item>
      </div>
      <div class="nav-bottom">
        <button @click="onLogout" class="btn-logout">로그아웃</button>
      </div>
    </div>
  </div>
</template>

<script>
import { getter } from 'shared/utils/storeUtils';
import PointIcon from '@/views/components/coaching/PointIcon.vue';
import MyPageNavTabItem from '@/views/components/coaching/coach-my-page/MyPageNavTabItem.vue';
import PointBuyModal from '@/views/components/coaching/PointBuyModal.vue';

export default {
  name: 'MyPageNav',
  components: { MyPageNavTabItem, PointIcon },
  props: {
    myInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    /** @type{MyInfo} */
    m() {
      return this.myInfo;
    },
    nickname() {
      return this.m?.nickname ?? '이름없음';
    },
    upBalanceAmount() {
      return this.m?.wallet?.upBalanceAmount ?? 0;
    },
    isCoach: getter('auth', 'isCoach'),
  },
  methods: {
    onLogout() {
      this.$services.auth.logout();
    },
    onRecharge() {
      // this.$modal(PointBuyModal);
      alert('포인트 충전이 불가합니다.');
    },
  },
};
</script>

<style lang="less">
@import '~@/less/coaching.less';
[my-page-nav] {.bgc(transparent);.block;.rel;.h(100%);
  .nav-head {.mb(50);}
  [point-icon] {.mt(25)}
  .intro-text {.fs(20);.lh(120%);flex-grow: 1;}
  .btn-logout {.mt(46);display: inline-block;
    &:hover, &:active {border-bottom: solid 1px #000;}
  }
  .nav-content {.w(266);.t(220);.ml(20); .sticky; .ib; .pb(30)}
  .nav-bottom { text-align: right;}
}
</style>
